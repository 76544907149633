import React, { useEffect, useState } from 'react'
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    Link as MLink,
    Snackbar,
    TextField,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const UpdateCity = (props) => {
    const cityId = props.match.params.cityId
    const token = localStorage.getItem('tgToken')
    const [name, setName] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const getData = () => {
        setLoading(false)
        const url = config.url + '/cities/' + cityId
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        axios.get(url, headerConfig).then(response => {
            setName(response.data.name)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const nameChange = (e) => {
        var value = e.target.value
        setName(value)
    }

    const trim = (value) => {
        value = ltrim(value)
        value = rtrim(value)
        return value
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            name: trim(name)
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/cities/' + cityId
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Updated.')
            setShowAlert(true)
            setLoading(false)
        }).catch(error => {
            let message
            if (error.response) {
                config.debug && console.log(error.response)
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                else if (error.response.status === 409) {
                    message = error.response.data.detail
                }
                else {
                    message = error.response.data.detail[0].msg
                }
            } else if (error.request) {
                config.debug && console.log(error.request)
                message = 'Try after some time.'
            } else {
                config.debug && console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/cities">
                    Cities
                </MLink>
                <Typography color="textPrimary">Update City</Typography>
            </Breadcrumbs>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit} className="mt-5">
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="category"
                                    label="City Name"
                                    name="category"
                                    value={name}
                                    variant="outlined"
                                    inputProps={{ minLength: 2, maxLength: 150 }}
                                    onChange={nameChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Link to="/dashboard/cities">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Back
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default UpdateCity
