import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import LogoDark from '../assets/images/logo/logo-dark.png'
import LogoLight from '../assets/images/logo/logo-light.png'

import {
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Snackbar,
    Toolbar,
    Tooltip
} from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import Brightness7Icon from '@material-ui/icons/Brightness7'

import { Redirect, Route, Switch } from 'react-router-dom'

import ProfileMenu from './ProfileMenu'
import SideNav from './SideNav'
import Dashboard from '../pages/Dashboard'
import ListUsers from '../pages/settings/users/ListUsers'
import ListRoles from '../pages/settings/roles/ListRoles'
import AddUser from '../pages/settings/users/AddUser'
import UpdateUser from '../pages/settings/users/UpdateUser'
import AddRole from '../pages/settings/roles/AddRole'
import UpdateRole from '../pages/settings/roles/UpdateRole'
import Profile from '../pages/settings/users/profile/Profile'
import MuiAlert from '@material-ui/lab/Alert'
import Unauthorized from '../pages/authentication/Unauthorized'
import ResetPassword from '../pages/settings/users/ResetPassword'
import ListFaqs from '../pages/faqs/ListFaqs'
import AddFaq from '../pages/faqs/AddFaq'
import UpdateFaq from '../pages/faqs/UpdateFaq'

import ListBusiness from '../pages/business/ListBusiness'
import AddBusiness from '../pages/business/AddBusiness'
import UpdateBusiness from '../pages/business/UpdateBusiness'
import BusinessCustomers from '../pages/business/BusinessCustomers'
import ListDeletedBusiness from '../pages/business/ListDeletedBusiness'
import BusinessTicketAccess from '../pages/business/BusinessTicketAccess'
import ListBusinessTickets from '../pages/business/ListBusinessTickets'

import ListCustomer from '../pages/customer/ListCustomer'
import CustomerDetails from '../pages/customer/CustomerDetails'
import ListDeletedCustomers from '../pages/customer/ListDeletedCustomers'

import DisplayHelp from '../pages/help/DisplayHelp'
import UpdateHelp from '../pages/help/UpdateHelp'

import ListTickets from '../pages/tickets/ListTickets'
import TicketDetails from '../pages/tickets/TicketDetails'
import ListDisabledTickets from '../pages/tickets/ListDisabledTickets'

import TicketSettings from '../pages/ticketSettings/TicketSettings'

// import ListGlassType from '../pages/glassType/ListGlassType'
// import AddGlassType from '../pages/glassType/AddGlassType'
// import UpdateGlassType from '../pages/glassType/UpdateGlassType'

import ListWarrantyReason from '../pages/warrantyReason/ListWarrantyReason'
import AddWarrantyReason from '../pages/warrantyReason/AddWarrantyReason'
import UpdateWarrantyReason from '../pages/warrantyReason/UpdateWarrantyReason'

// import ListCategory from '../pages/categories/ListCategory'
// import AddCategory from '../pages/categories/AddCategory'
// import UpdateCategory from '../pages/categories/UpdateCategory'

// import ListPublicTemplates from '../pages/templates/ListPublicTemplates'
// import ListCustomerTemplates from '../pages/templates/ListCustomerTemplates'

import ListSupport from '../pages/settings/ListSupport'

import ListTrolley from '../pages/trolley/trolleys/ListTrolley'
import AddTrolley from '../pages/trolley/trolleys/AddTrolley'
import EditTrolley from '../pages/trolley/trolleys/EditTrolley'
import ViewTrolley from '../pages/trolley/trolleys/ViewTrolley'
import ViewTrolleyHistory from '../pages/trolley/trolleys/ViewTrolleyHistory'
import ListDeletedTrolley from '../pages/trolley/trolleys/ListDeletedTrolley'
import ListCost from '../pages/trolley/trolleys/ListCost'
import ListCustomerTrolley from '../pages/trolley/trolleys/ListCustomerTrolley'

import ListCity from '../pages/cities/ListCity'
import AddCity from '../pages/cities/AddCity'
import UpdateCity from '../pages/cities/UpdateCity'

import axios from 'axios'
import config from '../config'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    }
}))

const Layout = (props) => {
    const classes = useStyles()
    const [open, setOpen] = useState(true)
    const operations = JSON.parse(localStorage.getItem('bsOperations'))
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const token = localStorage.getItem('tgToken')

    const getOperations = () => {
        if (token !== null) {
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            const url = config.url + '/operations'
            axios.get(url, headerConfig).then(response => {
                const operations = response.data.operations
                localStorage.setItem('bsOperations', JSON.stringify(operations))
            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 402) {
                        localStorage.clear()
                        props.history.push('/login')
                    } else {
                        config.debug && console.log(error.response)
                    }
                } else if (error.request) {
                    config.debug && console.log(error.request)
                } else {
                    config.debug && console.log(error)
                }
            })
        }
    }

    const getCommentPermissions = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/tickets/permissions'
        axios.get(url, headerConfig).then(response => {
            const data = response.data
            localStorage.setItem('tgComment', JSON.stringify(data))
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
        })
    }

    useEffect(() => {
        if (token === null) {
            var location = '/login'
            props.history.push(location)
        }
        getOperations()
        getCommentPermissions()
        // eslint-disable-next-line
    }, [])

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const checkOperations = (operation) => {
        const status = operations.includes(operation)
        if (status === false) {
            setAlertType('error')
            setAlertMessage('You don\'t have permission.')
            setShowAlert(true)
        }
        return status
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const lightButton = (props.darkState) ? (<Tooltip title="Light"><Brightness7Icon style={{ color: "white" }} /></Tooltip>) : (<Tooltip title="Dark"><Brightness4Icon style={{ color: "white" }} /></Tooltip>)

    const logo = (props.darkState) ? LogoLight : LogoDark

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Grid container spacing={3}>
                        <Grid item xs={11}></Grid>
                    </Grid>
                    <IconButton onClick={props.handleThemeChange}>
                        {lightButton}
                    </IconButton>
                    <ProfileMenu />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <img src={logo} onClick={handleDrawerClose} alt="Logo" height="64" className="cursor-pointer" />
                <Divider />
                <SideNav />
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/dashboard/profile" component={Profile} />
                    <Route exact path="/dashboard/unauthorized" component={Unauthorized} />

                    <Route exact path="/dashboard/business"
                        render={(props) => {
                            var status = checkOperations('List Business')
                            if (status === true) {
                                return (<ListBusiness {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/business/add"
                        render={(props) => {
                            var status = checkOperations('Add Business')
                            if (status === true) {
                                return (<AddBusiness {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/business/trash"
                        render={(props) => {
                            var status = checkOperations('List Deleted Business')
                            if (status === true) {
                                return (<ListDeletedBusiness {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/business/:businessId"
                        render={(props) => {
                            var status = checkOperations('Update Business')
                            if (status === true) {
                                return (<UpdateBusiness {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/business/:businessId/settings"
                        render={(props) => {
                            var status = checkOperations('Update Business')
                            if (status === true) {
                                return (<BusinessTicketAccess {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/business/:businessId/customers"
                        render={(props) => {
                            var status = checkOperations('View Business')
                            if (status === true) {
                                return (<BusinessCustomers {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/business/:businessId/tickets"
                        render={(props) => {
                            var status = checkOperations('List Tickets')
                            if (status === true) {
                                return (<ListBusinessTickets {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/customers"
                        render={(props) => {
                            var status = checkOperations('List Customers')
                            if (status === true) {
                                return (<ListCustomer {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/trash"
                        render={(props) => {
                            var status = checkOperations('List Deleted Customers')
                            if (status === true) {
                                return (<ListDeletedCustomers {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/:customerId"
                        render={(props) => {
                            var status = checkOperations('View Customer')
                            if (status === true) {
                                return (<CustomerDetails {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/faqs"
                        render={(props) => {
                            var status = checkOperations('List FAQs')
                            if (status === true) {
                                return (<ListFaqs {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/faqs/add"
                        render={(props) => {
                            var status = checkOperations('Add FAQ')
                            if (status === true) {
                                return (<AddFaq {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/faqs/:faqId"
                        render={(props) => {
                            var status = checkOperations('Update FAQ')
                            if (status === true) {
                                return (<UpdateFaq {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/help"
                        render={(props) => {
                            var status = checkOperations('List Help')
                            if (status === true) {
                                return (<DisplayHelp {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/help/:helpId"
                        render={(props) => {
                            var status = checkOperations('Update Help')
                            if (status === true) {
                                return (<UpdateHelp {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/tickets"
                        render={(props) => {
                            var status = checkOperations('List Tickets')
                            if (status === true) {
                                return (<ListTickets {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/tickets/trash"
                        render={(props) => {
                            var status = checkOperations('List Deleted Tickets')
                            if (status === true) {
                                return (<ListDisabledTickets {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/Tickets/:ticketId"
                        render={(props) => {
                            var status = checkOperations('View Ticket')
                            if (status === true) {
                                return (<TicketDetails {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/ticketSettings"
                        render={(props) => {
                            var status = checkOperations('Update Ticket Settings')
                            if (status === true) {
                                return (<TicketSettings {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/cost"
                        render={(props) => {
                            var status = checkOperations('View Additional Cost')
                            if (status === true) {
                                return (<ListCost {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/trolleys"
                        render={(props) => {
                            var status = checkOperations('List Trolleys')
                            if (status === true) {
                                return (<ListTrolley {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/trolleys/add"
                        render={(props) => {
                            var status = checkOperations('Add Trolley')
                            if (status === true) {
                                return (<AddTrolley {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/trolleys/deleted"
                        render={(props) => {
                            var status = checkOperations('List Trolleys')
                            if (status === true) {
                                return (<ListDeletedTrolley {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/trolleys/customers"
                        render={(props) => {
                            var status = checkOperations('List Trolleys')
                            if (status === true) {
                                return (<ListCustomerTrolley {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/trolleys/:trolleyId"
                        render={(props) => {
                            var status = checkOperations('View Trolley')
                            if (status === true) {
                                return (<ViewTrolley {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/trolleys/:trolleyId/edit"
                        render={(props) => {
                            var status = checkOperations('Update Trolley')
                            if (status === true) {
                                return (<EditTrolley {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/trolleys/:trolleyId/:historyId"
                        render={(props) => {
                            var status = checkOperations('View Trolley')
                            if (status === true) {
                                return (<ViewTrolleyHistory {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/cities"
                        render={(props) => {
                            var status = checkOperations('List Cities')
                            if (status === true) {
                                return (<ListCity {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/cities/add"
                        render={(props) => {
                            var status = checkOperations('Add City')
                            if (status === true) {
                                return (<AddCity {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/cities/:cityId"
                        render={(props) => {
                            var status = checkOperations('Update City')
                            if (status === true) {
                                return (<UpdateCity {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    {/* <Route exact path="/dashboard/glassType"
                        render={(props) => {
                            var status = checkOperations('List Glass Types')
                            if (status === true) {
                                return (<ListGlassType {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/glassType/add"
                        render={(props) => {
                            var status = checkOperations('Add Glass Type')
                            if (status === true) {
                                return (<AddGlassType {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/glassType/:glassTypeId"
                        render={(props) => {
                            var status = checkOperations('Update Glass Type')
                            if (status === true) {
                                return (<UpdateGlassType {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    /> */}

                    <Route exact path="/dashboard/warrantyReason"
                        render={(props) => {
                            var status = checkOperations('List Warranty Reasons')
                            if (status === true) {
                                return (<ListWarrantyReason {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/warrantyReason/add"
                        render={(props) => {
                            var status = checkOperations('Add Warranty Reason')
                            if (status === true) {
                                return (<AddWarrantyReason {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/warrantyReason/:warrantyReasonId"
                        render={(props) => {
                            var status = checkOperations('Update Warranty Reason')
                            if (status === true) {
                                return (<UpdateWarrantyReason {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    {/* <Route exact path="/dashboard/categories"
                        render={(props) => {
                            var status = checkOperations('List Categories')
                            if (status === true) {
                                return (<ListCategory {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/categories/add"
                        render={(props) => {
                            var status = checkOperations('Add Category')
                            if (status === true) {
                                return (<AddCategory {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/categories/:categoryId"
                        render={(props) => {
                            var status = checkOperations('Update Category')
                            if (status === true) {
                                return (<UpdateCategory {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/templates/public"
                        render={(props) => {
                            var status = checkOperations('List Templates')
                            if (status === true) {
                                return (<ListPublicTemplates {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/templates/customer"
                        render={(props) => {
                            var status = checkOperations('List Templates')
                            if (status === true) {
                                return (<ListCustomerTemplates {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    /> */}

                    <Route exact path="/dashboard/support"
                        render={(props) => {
                            var status = checkOperations('List Support')
                            if (status === true) {
                                return (<ListSupport {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/settings/users"
                        render={(props) => {
                            var status = checkOperations('List Users')
                            if (status === true) {
                                return (<ListUsers {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/add"
                        render={(props) => {
                            var status = checkOperations('Add User')
                            if (status === true) {
                                return (<AddUser {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/:userId"
                        render={(props) => {
                            var status = checkOperations('Update User')
                            if (status === true) {
                                return (<UpdateUser {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/reset-password/:userId"
                        render={(props) => {
                            var status = checkOperations('Reset Password')
                            if (status === true) {
                                return (<ResetPassword {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles"
                        render={(props) => {
                            var status = checkOperations('List Roles')
                            if (status === true) {
                                return (<ListRoles {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles/add"
                        render={(props) => {
                            var status = checkOperations('Add Role')
                            if (status === true) {
                                return (<AddRole {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles/:roleId"
                        render={(props) => {
                            var status = checkOperations('Update Role')
                            if (status === true) {
                                return (<UpdateRole {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                </Switch>
            </main>
        </div>
    )
}

export default Layout
