import React, { useCallback, useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Slider,
    DialogActions,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from '../../../canvasUtils'
import { DropzoneArea } from 'material-ui-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import Cropper from 'react-easy-crop'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { dateToString } from '../../../functions';

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const useStyles = makeStyles((theme) => ({
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
            height: 400,
        },
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    controls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    sliderLabel: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 65,
        },
    },
    slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },
    },
    paper: { minWidth: "1000px" },
}));

const EditTrolley = (props) => {
    const classes = useStyles();
    const trolleyId = props.match.params.trolleyId
    const token = localStorage.getItem('tgToken')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false);
    const [serialNumber, setSerialNumber] = useState('')
    const [isCertified, setIsCertified] = useState(false)
    const [expiryDate, setExpiryDate] = useState(null)

    // Cropper
    const [fileOpen, setFileOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [mediaPreview, setMediaPreview] = useState('')
    const [thumbImage, setThumbImage] = useState(null)

    // Cropping Process
    const handleSelectFileOpen = () => {
        setFileOpen(true)
    }
    const handleSelectFileClose = () => {
        setFileOpen(false)
        setImageSrc(null)
    }
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])
    const imageChange = async (files) => {
        if (files && files.length > 0) {
            const file = files[0]
            let imageDataUrl = await readFile(file)
            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }
            setImageSrc(imageDataUrl)
        } else {
            setImageSrc(null)
        }
    }
    const updateImage = async () => {
        setLoading(true)
        setDisabled(true)
        const croppedImageBase64 = await getCroppedImg(
            imageSrc,
            croppedAreaPixels
        )
        setMediaPreview(<img src={croppedImageBase64} alt="" className="img-fluid" height="100px" />)
        setThumbImage(croppedImageBase64)
        handleSelectFileClose()
        setDisabled(false)
        setLoading(false)
    }

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/trolleys/' + trolleyId
        axios.get(url, headerConfig).then(response => {
            const item = response.data
            setName(item.name)
            setEmail(item.uid)
            setMediaPreview(<img src={item.trolley_photo_url} alt="" className="img-fluid" height="100px" />)
            setSerialNumber(item.serial_number)
            setIsCertified(item.is_certified)
            if (item.expiry_date) {
                setExpiryDate(moment(item.expiry_date))
            }
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const handleNameChange = (e) => {
        var value = e.target.value
        setName(value)
    }

    const handleEmailChange = (e) => {
        var value = e.target.value
        setEmail(value)
    }

    const handleSerialNumberChange = (e) => {
        var value = e.target.value
        setSerialNumber(value)
    }

    const handleExpiryDateChange = (selectedDate) => {
        var date
        if (selectedDate) {
            date = moment(selectedDate)
        } else {
            date = null
        }
        setExpiryDate(date)
    }

    const trim = (value) => {
        value = ltrim(value)
        value = rtrim(value)
        return value
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (expiryDate !== null && expiryDate._isValid === false) {
            setAlertType('error')
            setAlertMessage('Please select a valid expiry date.')
            setShowAlert(true)
            return
        }

        setLoading(true)
        const data = {
            name: trim(name),
            uid: trim(email),
            trolley_photo_url: thumbImage,
            serial_number: serialNumber ? trim(serialNumber) : null,
            is_certified: isCertified,
            expiry_date: (expiryDate !== null && expiryDate._isValid) ? dateToString(expiryDate) : null
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/trolleys/' + trolleyId
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Updated.')
            setShowAlert(true)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 409) {
                    message = error.response.data.detail
                } else if (error.response.status === 422) {
                    if (error.response.data.detail.length > 0) {
                        message = error.response.data.detail[0].loc[1] + ' ' + error.response.data.detail[0].msg
                    } else {
                        message = error.response.data.detail.msg
                    }
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/trolleys">
                    Trolleys
                </MLink>
                <Typography color="textPrimary">Update trolley</Typography>
            </Breadcrumbs>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid item xs={12} md={4} className="mt-5">
                            <TextField
                                id="name"
                                name="name"
                                label="Name"
                                variant="outlined"
                                inputProps={{ minLength: 2, maxLength: 36 }}
                                value={name}
                                onChange={handleNameChange}
                                fullWidth
                                required />
                            <br /><br />
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                variant="outlined"
                                inputProps={{ minLength: 1, maxLength: 36 }}
                                value={email}
                                onChange={handleEmailChange}
                                fullWidth
                                required />
                            <br /><br />
                            <TextField
                                id="serial_number"
                                name="serial_number"
                                label="Serial Number"
                                variant="outlined"
                                inputProps={{ maxLength: 100 }}
                                onChange={handleSerialNumberChange}
                                fullWidth
                                value={serialNumber}
                            />
                            <br /><br />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isCertified}
                                        onChange={e => setIsCertified(e.target.checked)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Certified"
                            />
                            <br /><br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    label="Expiry Date"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={expiryDate}
                                    className="mt-4"
                                    onChange={handleExpiryDateChange}
                                />
                            </MuiPickersUtilsProvider>
                            <br /><br />
                            <Button variant="contained" color="primary" className="mt-3" onClick={handleSelectFileOpen} disabled={disabled}>
                                Select Image
                            </Button>
                            <div className="mt-4">
                                <br />
                                {mediaPreview}
                            </div>
                            <br /><br /><br />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Link to="/dashboard/trolleys"><Button variant="contained">Cancel</Button></Link>
                            <Button variant="contained" color="primary" className="ml-2" type="submit" disabled={loading}>
                                Update
                            </Button>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <Dialog
                classes={{ paper: classes.paper }}
                open={fileOpen}
                onClose={handleSelectFileClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {progress}
                <div>
                    <DialogTitle id="alert-dialog-title">Upload Image</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {imageSrc ? (
                                <div>
                                    <div className={classes.cropContainer}>
                                        <Cropper
                                            image={imageSrc}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={1 / 1}
                                            onCropChange={setCrop}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                        />
                                    </div>
                                    <div className={classes.controls}>
                                        <div className={classes.sliderContainer}>
                                            <Typography
                                                variant="overline"
                                                classes={{ root: classes.sliderLabel }}
                                            >
                                                Zoom
                                            </Typography>
                                            <Slider
                                                value={zoom}
                                                min={1}
                                                max={3}
                                                step={0.1}
                                                aria-labelledby="Zoom"
                                                classes={{ root: classes.slider }}
                                                onChange={(e, zoom) => setZoom(zoom)}
                                            />
                                        </div>
                                        <Button onClick={handleSelectFileClose} variant="contained" autoFocus>
                                            Close
                                        </Button>
                                        <Button variant="contained" color="warning" className="ml-2" onClick={updateImage} disabled={disabled}>
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <DropzoneArea
                                    filesLimit={1}
                                    dropzoneText="Drag and drop an image here or click"
                                    showAlerts={['error']}
                                    acceptedFiles={['image/*']}
                                    onChange={imageChange}
                                />
                            )}
                        </DialogContentText>
                    </DialogContent>
                    {imageSrc ? ('') : (
                        <DialogActions>
                            <Button onClick={handleSelectFileClose} variant="contained" autoFocus>
                                Close
                            </Button>
                            <Button variant="contained" color="warning" className="ml-2">
                                Update
                            </Button>
                        </DialogActions>)}
                </div>
            </Dialog>
        </div>
    )
}

export default EditTrolley
