import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    Breadcrumbs,
    CardActionArea,
    CardMedia,
    Grid,
    LinearProgress,
    Link as MLink,
    Tooltip,
    Typography
} from '@material-ui/core';
import axios from 'axios'
import config from '../../config'
import Snackbar from '@material-ui/core/Snackbar'
import { Link } from 'react-router-dom'
import { dateFormate } from '../../functions'
import MuiAlert from '@material-ui/lab/Alert'

import ListTicketNotification from './ListTicketNotification'
import ListTicketComments from './ListTicketComments'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const TicketDetails = (props) => {
    const ticketId = props.match.params.ticketId
    const token = localStorage.getItem('tgToken')
    const permissions = JSON.parse(localStorage.getItem('tgComment'))
    const [ticket, setTicket] = useState({})
    const [note, setNote] = useState('')
    const [labelImages, setLabelImages] = useState([])
    const [defectImages, setDefectImages] = useState([])
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/tickets/' + ticketId
        axios.get(url, headerConfig).then(response => {
            const ticket = response.data
            let defectImages = []
            let labelImages = []
            if (ticket.business_id !== '') {
                const business = (
                    <Tooltip title="View Business">
                        <Link to={'/dashboard/business/' + ticket.business_id} className="link">
                            {ticket.business_name}
                        </Link>
                    </Tooltip>
                )
                ticket.business_name = business
            }
            const images = ticket.images
            images.map(image => {
                if (image.type === 'Defect') {
                    defectImages.push(image)
                } else if (image.type === 'Label') {
                    labelImages.push(image)
                }
                return true
            })
            setTicket(ticket)
            setNote(ticket.note)
            setDefectImages(defectImages)
            setLabelImages(labelImages)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const defectImagesComponent = (defectImages.length > 0) ? (
        <>
            <CardHeader
                subheader="Defect Images:"
            />
            <Grid container spacing={4}>
                {
                    defectImages.map((image, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={4} md={3}>
                                <Card>
                                    <a href={image.url} target="_blank" rel="noreferrer">
                                        <CardActionArea>
                                            <CardMedia style={{ height: 250 }}
                                                image={image.url}
                                                title="Defect Image"
                                            />
                                        </CardActionArea>
                                    </a>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    ) : ''

    const labelImagesComponent = (labelImages.length > 0) ? (
        <>
            <CardHeader
                subheader="Label Images:"
            />
            <Grid container spacing={4}>
                {
                    labelImages.map((image, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={4} md={3}>
                                <Card>
                                    <a href={image.url} target="_blank" rel="noreferrer">
                                        <CardActionArea>
                                            <CardMedia style={{ height: 250 }}
                                                image={image.url}
                                                title="Label Image"
                                            />
                                        </CardActionArea>
                                    </a>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    ) : ''

    const noteComponent = (note !== '') ? (
        <>
            <br />
            <Card>
                <CardHeader
                    subheader="Additional Notes:"
                />
                <CardContent>
                    <div dangerouslySetInnerHTML={{ __html: ticket.note }} />
                </CardContent>
            </Card>
        </>
    ) : ''

    const progress = loading ? (<LinearProgress />) : ('')

    const comment = permissions.view_comment ? (<ListTicketComments ticketId={ticketId} />) : ('')

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/tickets">
                    Tickets
                </MLink>
                <Typography color="textPrimary">Ticket Details - {ticket.ticket_id}</Typography>
            </Breadcrumbs>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} md={4} className="text-right">
                                    Glass Order No:
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    {ticket.glass_order_no}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} md={4} className="text-right">
                                    Customer PO No:
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    {ticket.customer_po_no}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} md={4} className="text-right">
                                    Glass Type:
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    {ticket.glass_type}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} md={4} className="text-right">
                                    Glass Size:
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    {ticket.glass_size}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} md={4} className="text-right">
                                    Customer Received Date:
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    {dateFormate(ticket.received_date)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} md={4} className="text-right">
                                    Expected Replacement Date:
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    {dateFormate(ticket.expected_date)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} md={4} className="text-right">
                                    Warranty Reason:
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    {ticket.warranty_reason}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} md={4} className="text-right">
                                    Marks:
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    {ticket.marks}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} md={4} className="text-right">
                                    Customer:
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    <Tooltip title="View Customer">
                                        <Link to={'/dashboard/customers/' + ticket.customer_id} className="link">
                                            {ticket.name}
                                        </Link>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} md={4} className="text-right">
                                    Business:
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    {ticket.business_name}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {noteComponent}
            {labelImagesComponent}
            {defectImagesComponent}
            <ListTicketNotification ticketId={ticketId} />
            {comment}
        </div>
    )
}

export default TicketDetails
