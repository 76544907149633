import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Button,
    Grid,
    TextField
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const UserDetails = () => {
    const token = localStorage.getItem('tgToken')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [user, setUser] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')

    useEffect(() => {
        getProfile()
        // eslint-disable-next-line
    }, [])

    const getProfile = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/user'
        axios.get(url, headerConfig).then(response => {
            const user = response.data
            setUser(user)
            setFirstName(user.first_name)
            setLastName(user.last_name)
            setEmail(user.email)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                } else {
                    config.debug && console.log(error.response)
                }
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
        })
    }

    const handleFirstNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setFirstName(value)
    }

    const handleLastNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setLastName(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setDisabled(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const data = {
            first_name: firstName,
            last_name: lastName,
        }
        const url = config.url + '/profile'
        axios.put(url, data, headerConfig).then(response => {
            user.first_name = firstName
            user.last_name = lastName
            localStorage.setItem('tgUser', JSON.stringify(user))
            setAlertType('success')
            setAlertMessage('Profile updated.')
            setShowAlert(true)
            setDisabled(false)
        }).catch(error => {
            let message
            if (error.response) {
                message = 'Try after some time.'
                if (error.response.status === 401) {
                    setAlertType('error')
                    message = error.response.data.detail
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
                message = 'Try after some time.'
            } else {
                config.debug && console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setDisabled(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Card>
                <CardContent>
                    <form id="pro" name="pro" onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <Grid item xs={3} className="mt-5">
                                <TextField
                                    key="email"
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    value={email}
                                    fullWidth
                                    disabled
                                    required />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    key="fName"
                                    id="fName"
                                    name="fName"
                                    label="First Name"
                                    variant="outlined"
                                    value={firstName}
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={handleFirstNameChange}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    key="lName"
                                    id="lName"
                                    name="lName"
                                    label="Last Name"
                                    variant="outlined"
                                    value={lastName}
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={handleLastNameChange}
                                    fullWidth
                                    required />
                            </Grid>
                            <Grid item xs={3}>
                                <Link to="/dashboard/settings/users">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={disabled}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default UserDetails
