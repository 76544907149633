import React, { useState, useEffect } from 'react'
import {
    Avatar,
    Breadcrumbs,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../components/Table'
import axios from 'axios'
import config from '../../config'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Link } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const BusinessCustomers = (props) => {
    const businessId = props.match.params.businessId
    const token = localStorage.getItem('tgToken')
    const [name, setName] = useState('Business')
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "image",
            label: "Image",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <Avatar alt="Profile Image" src={value} />
                }
            }
        },
        {
            name: "first_name",
            label: "First Name",
            options: {
                sort: true,
            }
        },
        {
            name: "last_name",
            label: "Last Name",
            options: {
                sort: true,
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                sort: true,
            }
        },
        {
            name: "number",
            label: "Number",
            options: {
                sort: true,
            }
        },
        {
            name: "is_verified",
            label: "Verification",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (value) ? (
                        <p className="verified">
                            Verified
                        </p>
                    ) : (
                        <p className="un-verified">
                            Un-Verified
                        </p>
                    )
                }
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/business/' + businessId
        axios.get(url, headerConfig).then(response => {
            const business = response.data
            setName(business.name)
            getList()
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setLoading(false)
        })
    }

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/business/' + businessId + '/customers'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                const viewUrl = '/dashboard/customers/' + item.id
                const emailUrl = 'mailto:' + item.email
                const numberUrl = 'tel:' + item.number
                const email = (
                    <Tooltip title="Send Email">
                        <MLink href={emailUrl} className="link" color="inherit">
                            {item.email}
                        </MLink>
                    </Tooltip>
                )
                const number = (
                    <Tooltip title="Call">
                        <MLink href={numberUrl} className="link" color="inherit">
                            {item.number}
                        </MLink>
                    </Tooltip>
                )
                const action = (
                    <div>
                        <Link to={viewUrl}>
                            <Tooltip title="View Details">
                                <IconButton>
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </div>
                )
                item.email = email
                item.number = number
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <MLink color="inherit" href="/#/dashboard/business">
                            Business
                        </MLink>
                        <Typography color="textPrimary">Employees of {name}</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Table
                title={'Employees'}
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
        </div>
    )
}

export default BusinessCustomers
