import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    Button,
    Paper,
    Grid,
    Link as MLink,
    Snackbar,
    Typography
} from '@material-ui/core'
import axios from 'axios'
import config from '../../config'
import { Link } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const DisplayHelp = (props) => {
    const token = localStorage.getItem('tgToken')
    const [help, setHelp] = useState({})
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)

    const getData = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/help'
        axios.get(url, headerConfig).then(response => {
            setHelp(response.data)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    message = error.response.data.detail
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Help</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item >
                    <Link to={'help/' + help.id}><Button variant="contained" color="primary">Edit</Button></Link>
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Paper style={{ padding: "15px" }}>
                <div dangerouslySetInnerHTML={{ __html: help.content }} />
            </Paper>
        </div>
    )
}

export default DisplayHelp
