import React, { useState } from 'react'
import {
    Card,
    CardContent,
    Button,
    Grid,
    TextField,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ChangePassword = () => {
    const token = localStorage.getItem('tgToken')
    const [oldPassword, setOldPassword] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')

    const handleOldPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setOldPassword(value)
    }

    const handleNewPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setNewPassword(value)
    }

    const handleConfirmPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setConfirmPassword(value)
    }

    const onPasswordUpdate = (e) => {
        e.preventDefault()
        if (newPassword !== confirmPassword) {
            setAlertMessage('Passwords are not same.')
            setAlertType('error')
            setShowAlert(true)
        }
        else {
            setDisabled(true)
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            const url = config.url + '/change-password'
            const data = {
                old_password: oldPassword,
                new_password: newPassword
            }
            axios.put(url, data, headerConfig).then(response => {
                setAlertMessage('Password changed.')
                setAlertType('success')
                setShowAlert(true)
                setDisabled(false)
            }).catch(error => {
                let message = 'Try after some time.'
                if (error.response) {
                    if (error.response.status === 401) {
                        message = error.response.data.detail
                    } else if (error.response.status === 403) {
                        message = error.response.data.detail
                    } else {
                        config.debug && console.log(error.response)
                    }
                } else if (error.request) {
                    config.debug && console.log(error.request)
                } else {
                    config.debug && console.log(error.message)
                }
                setDisabled(false)
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
            })
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Card>
                <CardContent>
                    <form id="pass" name="pass" onSubmit={onPasswordUpdate}>
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <Grid item xs={3} className="mt-5">
                                <TextField
                                    key="oldPassword"
                                    required
                                    fullWidth
                                    name="oldPassword"
                                    variant="outlined"
                                    label="Old Password"
                                    type="password"
                                    id="oldPassword"
                                    inputProps={{ minLength: 6, maxLength: 50 }}
                                    onChange={handleOldPasswordChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    key="newPassword"
                                    required
                                    fullWidth
                                    name="newPassword"
                                    variant="outlined"
                                    label="New Password"
                                    type="password"
                                    id="newPassword"
                                    inputProps={{ minLength: 6, maxLength: 50 }}
                                    onChange={handleNewPasswordChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    key="confirmPassword"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    variant="outlined"
                                    label="Confirm Password"
                                    type="password"
                                    id="confirmPassword"
                                    inputProps={{ minLength: 6, maxLength: 50 }}
                                    onChange={handleConfirmPasswordChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Link to="/dashboard/settings/users">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={disabled}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default ChangePassword
