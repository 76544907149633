import React, { useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AddFaq = (props) => {
    const token = localStorage.getItem('tgToken')
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [number, setNumber] = useState(null)
    const [address, setAddress] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)


    const handleNameChange = (e) => {
        var value = e.target.value
        setName(value)
    }

    const handleEmailChange = (e) => {
        var value = e.target.value
        setEmail(value)
    }

    const handleNumberChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setNumber(value)
    }

    const handleAddressChange = (e) => {
        var value = e.target.value
        setAddress(value)
    }

    const trim = (value) => {
        value = ltrim(value)
        value = rtrim(value)
        return value
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            name: trim(name),
            email: trim(email),
            contact: number,
            address: trim(address),
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/business'
        axios.post(url, data, headerConfig).then(response => {
            setLoading(false)
            props.history.push('/dashboard/business')
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 409) {
                    message = error.response.data.detail
                } else if (error.response.status === 422) {
                    if (error.response.data.detail.length > 0) {
                        message = error.response.data.detail[0].loc[1] + ' ' + error.response.data.detail[0].msg
                    } else {
                        message = error.response.data.detail.msg
                    }                    
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/business">
                    Business
                </MLink>
                <Typography color="textPrimary">Add Business</Typography>
            </Breadcrumbs>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid item xs={12} md={4} className="mt-5">
                            <TextField
                                id="name"
                                name="name"
                                label="Name"
                                variant="outlined"
                                inputProps={{ minLength: 3, maxLength: 255 }}
                                onChange={handleNameChange}
                                fullWidth
                                required />
                            <br /><br />
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                variant="outlined"
                                inputProps={{ minLength: 5, maxLength: 255, type: 'email' }}
                                onChange={handleEmailChange}
                                fullWidth
                                required />
                            <br /><br />
                            <TextField
                                id="contactNo"
                                name="contactNo"
                                label="Contact No"
                                variant="outlined"
                                inputProps={{ minLength: 10, maxLength: 13, title: "Numbers only", pattern: "*[0-9]" }}
                                onChange={handleNumberChange}
                                fullWidth
                                required />
                            <br /><br />
                            <TextField
                                id="address"
                                name="address"
                                label="Address"
                                variant="outlined"
                                rows={5}
                                inputProps={{ minLength: 3, maxLength: 255 }}
                                onChange={handleAddressChange}
                                fullWidth
                                required />
                            <br /><br /><br />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Link to="/dashboard/business"><Button variant="contained">Cancel</Button></Link>
                            <Button variant="contained" color="primary" className="ml-2" type="submit" disabled={loading}>
                                Add
                            </Button>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default AddFaq
