import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    Grid,
    LinearProgress,
    Link as MLink,
    Snackbar,
    TextField,
    Typography
} from '@material-ui/core'
import TableLocal from '../../../components/TableLocal'
import axios from 'axios'
import config from '../../../config'
import { dateTimeFormate } from '../../../functions'
import MuiAlert from '@material-ui/lab/Alert'
import { Autocomplete } from '@material-ui/lab'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ListCost = (props) => {
    const token = localStorage.getItem('tgToken')
    const [tableData, setTableData] = useState([])
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [customers, setCustomers] = useState([])
    const [customer, setCustomer] = useState(null)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return rowIndex + 1
                }
            }
        },
        {
            name: "created_at",
            label: "Delivery Date",
            options: {
                sort: true,
                customBodyRender: dateTimeFormate
            }
        },
        {
            name: "created_at",
            label: "Return Date",
            options: {
                sort: true,
                customBodyRender: dateTimeFormate
            }
        },
        {
            name: "charge_free_days",
            label: "No of Day Free",
            options: {
                sort: true,
            }
        },
        {
            name: "time_duration",
            label: "No of Day Hold",
            options: {
                sort: true,
            }
        },
        {
            name: "after_free_day_charge",
            label: "Per Day Charge",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return `$ ${value}`
                }
            }
        },
        {
            name: "cost",
            label: "Total",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return `$ ${value}`
                }
            }
        },
    ]

    const getList = () => {
        setLoading(true)
        const params = {
            customer_id: customer.id
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/trolleys/costs'
        axios.get(url, headerConfig).then(response => {
            setTableData(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setLoading(false)
        })
    }

    const getCustomers = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/all'
        axios.get(url, headerConfig).then(response => {
            setCustomers(response.data)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
        })
    }

    useEffect(() => {
        getCustomers()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (customer) {
            getList()
        } else {
            setTableData([])
        }
        // eslint-disable-next-line
    }, [customer])

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Additional Cost</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item xs={4}>
                    <Autocomplete
                        options={customers}
                        autoHighlight
                        getOptionLabel={(option) => option.first_name + ' ' + option.last_name + ' (' + option.number + ')'}
                        renderInput={(params) => <TextField
                            {...params}
                            label="Customer"
                            variant="outlined"
                            required
                        />}
                        onChange={(event, value) => setCustomer(value)}
                    />
                </Grid>
            </Grid>
            <br />
            {progress}
            <TableLocal
                title={'List Additional Cost'}
                columns={columns}
                data={tableData}
            />
        </div>
    )
}

export default ListCost
