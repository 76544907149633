import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    ButtonGroup,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../../components/Table'
import axios from 'axios'
import config from '../../../config'
import { Link } from 'react-router-dom'
import { dateTimeFormate, trolleyStatus } from '../../../functions'
import MuiAlert from '@material-ui/lab/Alert'
import ImageIcon from '@material-ui/icons/Image'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ViewTrolleyHistory = (props) => {
    const trolleyId = props.match.params.trolleyId
    const historyId = props.match.params.historyId
    const token = localStorage.getItem('tgToken')
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [openGallery, setOpenGallery] = useState(false)
    const [note, setNote] = useState(null)
    const [photos, setPhotos] = useState([])
    const [trolley, setTrolley] = useState(null)

    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "created_at",
            label: "Date",
            options: {
                sort: true,
                customBodyRender: dateTimeFormate
            }
        },
        {
            name: "customer",
            label: "Customer Name",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    let item = null

                    if (value) {
                        const customerUrl = '/dashboard/customers/' + value.id
                        item = (
                            <Tooltip title="View Customer">
                                <Link to={customerUrl} className="link">
                                    {value.first_name + ' ' + value.last_name}
                                </Link>
                            </Tooltip>
                        )
                    } else {
                        item = 'At ToughGlass'
                    }
                    return item
                }
            }
        },
        {
            name: "no_of_bars",
            label: "No of Bars",
            options: {
                sort: true,
            }
        },
        {
            name: "trolley_status",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: trolleyStatus
            }
        },
        {
            name: "user",
            label: "Update By",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value.first_name + ' ' + value.last_name
                }
            }
        },
        {
            name: "time_duration",
            label: "Time Duration",
            options: {
                sort: true,
            }
        },
        {
            name: "note",
            label: "Note",
            options: {
                sort: true,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search,
            disabled: 0
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/trolleys/' + trolleyId + '/transactions/' + historyId + '/history'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                let action = null
                if (item.photos.length > 0) {
                    action = (
                        <ButtonGroup>
                            <Tooltip title="Images">
                                <IconButton>
                                    <ImageIcon onClick={() => {
                                        setPhotos(item.photos)
                                        setOpenGallery(true)
                                    }} />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    )
                }
                if (item.note) {
                    const value = item.note
                    item.note = (
                        <Tooltip title="View Note">
                            <IconButton onClick={() => {
                                setNote(value)
                                setOpen(true)
                            }}>
                                <SpeakerNotesIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setLoading(false)
        })
    }

    const getTrolley = () => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search,
            disabled: 0
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/trolleys/' + trolleyId + '/transactions/' + historyId
        axios.get(url, headerConfig).then(response => {
            setTrolley(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getTrolley()
        getList()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleClose = () => {
        setOpen(false)
        setNote(null)
    }
    const handleCloseGallery = () => {
        setOpenGallery(false)
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <MLink color="inherit" href="/#/dashboard/trolleys">
                            Trolley
                        </MLink>
                        <MLink color="inherit" href={"/#/dashboard/trolleys/" + trolleyId}>
                            Transactions
                        </MLink>
                        <Typography color="textPrimary">History</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <h3>Trolley FSDS: {trolley && trolley.uid}</h3>
                </Grid>
                <Grid item >
                    <h3>Total Time On Plat to Return : {trolley && trolley.time_duration}</h3>
                </Grid>
            </Grid>
            <Card>
                {progress}
                <Table
                    title={'List Trolley'}
                    serverSide={true}
                    count={count}
                    columns={columns}
                    data={tableData}
                    onTableChange={handleTableChange}
                    rowsPerPage={limit}
                />
                <CardContent className='mt-5'>
                    <Grid container className='text-center'>
                        <Grid item xs={12} md={3}>
                            No of free day: {trolley && trolley.charge_free_days}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            After free days charge: $ {trolley && trolley.after_free_day_charge}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            Customer Hold Time: {trolley && trolley.time_duration}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            Additional Charge for {trolley && trolley.chargeable_days} Days * $ {trolley && trolley.after_free_day_charge} = $ {trolley && trolley.cost}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">Note</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {note}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openGallery}
                onClose={handleCloseGallery}
                maxWidth="md"
                fullWidth
            >
                <DialogContent className='img-gallery'>
                    <Slider {...sliderSettings}>
                        {photos.map((photo, index) => (
                            <>
                                {photo.name && <h2>Name: {photo.name}</h2>}
                                <img key={index} src={photo.url} alt="Slider img" className='img-fluid' />
                            </>

                        ))}
                    </Slider>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ViewTrolleyHistory
