import React, { useState, useEffect } from 'react'
import {
    Avatar,
    Breadcrumbs,
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Snackbar,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../../components/Table'
import axios from 'axios'
import config from '../../../config'
import { Link } from 'react-router-dom'
import { checkDateIsExpired, dateFormate, dateTimeFormate, trolleyStatus } from '../../../functions'
import MuiAlert from '@material-ui/lab/Alert'
import HistoryIcon from '@material-ui/icons/History'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import BlockIcon from '@material-ui/icons/Block'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { Autocomplete } from '@material-ui/lab'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ListCustomerTrolley = (props) => {
    const token = localStorage.getItem('tgToken')
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [open, setOpen] = useState(false)
    const [openDeFleet, setOpenDeFleet] = useState(false)
    const [itemId, setItemId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [customers, setCustomers] = useState([])
    const [customer, setCustomer] = useState(null)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "trolley_photo_url",
            label: "Image",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <Avatar alt="Trolley Image" src={value} />
                }
            }
        },
        {
            name: "name",
            label: "Trolley Name",
            options: {
                sort: true,
            }
        },
        {
            name: "uid",
            label: "UID",
            options: {
                sort: true,
            }
        },
        {
            name: "customer",
            label: "Customer Name",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    let item = null

                    if (value) {
                        const customerUrl = '/dashboard/customers/' + value.id
                        item = (
                            <Tooltip title="View Customer">
                                <Link to={customerUrl} className="link">
                                    {value.first_name + ' ' + value.last_name}
                                </Link>
                            </Tooltip>
                        )
                    } else {
                        item = 'At ToughGlass'
                    }
                    return item
                }
            }
        },
        {
            name: "trolley_status",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: trolleyStatus
            }
        },
        {
            name: "time_duration",
            label: "Time Duration",
            options: {
                sort: true,
            }
        },
        {
            name: "serial_number",
            label: "Serial Number",
            options: {
                sort: true,
            }
        },
        {
            name: "is_certified",
            label: "Certified",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    const item = value ? (
                        <>
                            <FiberManualRecordIcon style={{ color: 'green', paddingTop: '6px' }} /> Yes
                        </>
                    ) : 'No'
                    return item
                }
            }
        },
        {
            name: "expiry_date",
            label: "Expiry Date",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    let item = ''
                    if (value) {
                        const isExpired = checkDateIsExpired(value)
                        const date = dateFormate(value)
                        if (isExpired) {
                            item = (
                                <>
                                    <FiberManualRecordIcon style={{ color: 'red', paddingTop: '6px' }} /> {date}
                                </>
                            )
                        } else {
                            item = date
                        }
                    }
                    return item
                }
            }
        },
        {
            name: "created_at",
            label: "Create Date",
            options: {
                sort: true,
                customBodyRender: dateTimeFormate
            }
        },
        {
            name: "created_at",
            label: "Last Update",
            options: {
                sort: true,
                customBodyRender: dateTimeFormate
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search,
            customer_id: customer.id
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/trolleys/customers'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                const editUrl = item.id + '/edit'
                const viewUrl = item.id
                let deFleet = null

                if (item.trolley_status !== 'DeFleet') {
                    deFleet = (
                        <Tooltip title="DeFleet Trolley">
                            <IconButton onClick={() => handleClickDeFleetOpen(item.id)}>
                                <BlockIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )
                }

                const action = (
                    <ButtonGroup>
                        <Link to={editUrl}>
                            <Tooltip title="Edit Trolley">
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Tooltip title="History">
                            <Link to={viewUrl} className="link">
                                <IconButton>
                                    <HistoryIcon fontSize="small" />
                                </IconButton>
                            </Link>
                        </Tooltip>
                        {deFleet}
                        <Tooltip title="Delete">
                            <IconButton onClick={() => handleClickOpen(item.id)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                )
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setLoading(false)
        })
    }

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
        }
        const url = config.url + '/customers/all'
        axios.get(url, headerConfig).then(response => {
            setCustomers(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (customer) {
            getList()
        }
        // eslint-disable-next-line
    }, [customer])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const deleteItem = (itemId) => {
        setLoading(true)
        getList()
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/trolleys/' + itemId
        axios.delete(url, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Deleted.')
            setShowAlert(true)
            setOpen(false)
            getList()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const deFleetItem = (itemId) => {
        setLoading(true)
        getList()
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/trolleys/' + itemId + '/defleet'
        axios.put(url, {}, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Updated.')
            setShowAlert(true)
            setOpenDeFleet(false)
            getList()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 403) {
                    message = error.response.data.detail
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleClickOpen = (itemId) => {
        setOpen(true)
        setItemId(itemId)
    }

    const handleClickDeFleetOpen = (itemId) => {
        setOpenDeFleet(true)
        setItemId(itemId)
    }

    const handleClose = () => {
        setOpen(false)
        setOpenDeFleet(false)
        setItemId(null)
    }

    const handleCustomerChange = (event, value) => {
        setCustomer(value)
        setTableData([])
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Customer Trolley</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Trolley</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Trolley?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(itemId)} disabled={loading} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDeFleet}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">DeFleet Trolley</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to DeFleet the Trolley?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => deFleetItem(itemId)} disabled={loading} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {progress}
            <Grid
                container
                spacing={3}
                direction="row"
            >
                <Grid item lg={4} xs={12}>
                    <Autocomplete
                        options={customers}
                        autoHighlight
                        getOptionLabel={(option) => option.first_name + ' ' + option.last_name + ' - ' + option.number}
                        renderInput={(params) => <TextField {...params} label="Customer" variant="outlined" />}
                        onChange={handleCustomerChange}
                        value={customer}
                    />
                </Grid>
            </Grid>
            <br />
            <Table
                title={'List Customer Trolley'}
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
        </div>
    )
}

export default ListCustomerTrolley
