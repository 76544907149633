import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'

import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip
} from '@material-ui/core'

import ListIcon from '@material-ui/icons/List'
import HomeIcon from '@material-ui/icons/Home'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import SettingsIcon from '@material-ui/icons/Settings'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
// import AirplayIcon from '@material-ui/icons/Airplay'
import AcUnitIcon from '@material-ui/icons/AcUnit'
import PersonIcon from '@material-ui/icons/Person'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import BusinessIcon from '@material-ui/icons/Business'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import ForumIcon from '@material-ui/icons/Forum'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import BuildIcon from '@material-ui/icons/Build'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
// import StyleIcon from '@material-ui/icons/Style'
// import PublicIcon from '@material-ui/icons/Public';
// import BorderStyleIcon from '@material-ui/icons/BorderStyle'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import ReceiptIcon from '@material-ui/icons/Receipt'

import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../config'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    sideLinks: {
        textDecoration: 'none',
        color: 'unset',
    }
}));

const SideNav = () => {
    const token = localStorage.getItem('tgToken')
    const theme = useTheme()
    const classes = useStyles()
    const [settingToggle, setSettingToggle] = useState(false)
    const [utilityToggle, setUtilityToggle] = useState(false)
    const [trashToggle, setTrashToggle] = useState(false)
    const [trolleyToggle, setTrolleyToggle] = useState(false)
    const [operations, setOperations] = useState([])
    const [menu, setMenu] = useState([])
    const [showUtility, setShowUtility] = useState(false)
    const [showTrash, setShowTrash] = useState(false)
    const [showTrolley, setShowTrolley] = useState(true)

    const getOperations = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/operations'
        axios.get(url, headerConfig).then(response => {
            const menu = response.data.menu
            if (menu.includes('Glass Types') || menu.includes('Warranty Reasons') || menu.includes('FAQs') || menu.includes('Help')) {
                setShowUtility(true)
            }

            if (menu.includes('Trash')) {
                setShowTrash(true)
            }

            if (menu.includes('Trolley')) {
                setShowTrolley(true)
            }

            setOperations(response.data.operations)
            setMenu(response.data.menu)
        }).catch(error => {
            if (error.response) {
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
        })
    }

    useEffect(() => {
        getOperations()
        // eslint-disable-next-line
    }, [])

    const checkOperation = (item) => {
        const status = operations.includes(item)
        return status
    }

    const checkMenu = (operation) => {
        const status = menu.includes(operation)
        return status
    }

    const color = (theme.palette.type === 'light') ? "primary" : ""

    const utility = (showUtility) ? (
        <>
            <ListItem button onClick={() => setUtilityToggle(!utilityToggle)}>
                <ListItemIcon>
                    <Tooltip title="Utilities"><BuildIcon color={color} /></Tooltip>
                </ListItemIcon>
                <ListItemText primary="Utilities" />
                {utilityToggle ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={utilityToggle} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {/* {checkMenu('Glass Types') ? (
                        <Link to='/dashboard/glassType' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Glass Types"><AirplayIcon color={color} /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Glass Types" />
                            </ListItem>
                        </Link>
                    ) : ('')} */}

                    {checkMenu('Warranty Reasons') ? (
                        <Link to='/dashboard/warrantyReason' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Warranty Reasons"><AcUnitIcon color={color} /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Warranty Reasons" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkMenu('FAQs') ? (
                        <Link to='/dashboard/faqs' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="FAQs"><LiveHelpIcon color={color} /></Tooltip></ListItemIcon>
                                <ListItemText primary="FAQs" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkMenu('Help') ? (
                        <Link to='/dashboard/help' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="Help"><AssignmentLateIcon color={color} /></Tooltip></ListItemIcon>
                                <ListItemText primary="Help" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {/* {checkMenu('Categories') ? (
                        <Link to='/dashboard/categories' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Categories"><StyleIcon color={color} /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Categories" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkMenu('Templates') ? (
                        <Link to='/dashboard/templates/public' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Public Templates"><PublicIcon color={color} /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Public Templates" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkMenu('Templates') ? (
                        <Link to='/dashboard/templates/customer' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Customer Templates"><BorderStyleIcon color={color} /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Customer Templates" />
                            </ListItem>
                        </Link>
                    ) : ('')} */}
                </List>
            </Collapse>
        </>
    ) : ''

    const trash = (showTrash) ? (
        <>
            <ListItem button onClick={() => setTrashToggle(!trashToggle)}>
                <ListItemIcon>
                    <Tooltip title="Trash"><DeleteOutlineIcon color={color} /></Tooltip>
                </ListItemIcon>
                <ListItemText primary="Trash" />
                {trashToggle ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={trashToggle} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {checkOperation('List Deleted Business') ? (
                        <Link to='/dashboard/business/trash' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="Business"><BusinessIcon color={color} /></Tooltip></ListItemIcon>
                                <ListItemText primary="Business" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Deleted Customers') ? (
                        <Link to='/dashboard/customers/trash' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="Customers"><PeopleAltIcon color={color} /></Tooltip></ListItemIcon>
                                <ListItemText primary="Customers" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Deleted Tickets') ? (
                        <Link to='/dashboard/tickets/trash' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="Tickets"><ForumIcon color={color} /></Tooltip></ListItemIcon>
                                <ListItemText primary="Tickets" />
                            </ListItem>
                        </Link>
                    ) : ('')}
                </List>
            </Collapse>
        </>
    ) : ''

    const trolley = (showTrolley) ? (
        <>
            <ListItem button onClick={() => setTrolleyToggle(!trolleyToggle)}>
                <ListItemIcon>
                    <Tooltip title="Trolley"><ShoppingCartIcon color={color} /></Tooltip>
                </ListItemIcon>
                <ListItemText primary="Trolley" />
                {trolleyToggle ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={trolleyToggle} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {checkOperation('List Cities') ? (
                        <Link to='/dashboard/cities' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="Cities"><LocationCityIcon color={color} /></Tooltip></ListItemIcon>
                                <ListItemText primary="Cities" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Trolleys') ? (
                        <Link to='/dashboard/trolleys' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="Trolleys"><ShoppingCartIcon color={color} /></Tooltip></ListItemIcon>
                                <ListItemText primary="Trolleys" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('View Additional Cost') ? (
                        <Link to='/dashboard/cost' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="Additional Cost"><ReceiptIcon color={color} /></Tooltip></ListItemIcon>
                                <ListItemText primary="Additional Cost" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Trolleys') ? (
                        <Link to='/dashboard/trolleys/customers' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="Customer Trolly Status"><PersonIcon color={color} /></Tooltip></ListItemIcon>
                                <ListItemText primary="Customer Trolly" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Trolleys') ? (
                        <Link to='/dashboard/trolleys/deleted' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="Deleted Trolleys"><ShoppingCartIcon color={color} /></Tooltip></ListItemIcon>
                                <ListItemText primary="Deleted Trolleys" />
                            </ListItem>
                        </Link>
                    ) : ('')}
                </List>
            </Collapse>
        </>
    ) : ''

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            <Link to="/dashboard" className={classes.sideLinks}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Dashboard"><HomeIcon color={color} /></Tooltip></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
            </Link>

            {checkMenu('Business') ? (
                <Link to='/dashboard/business' className={classes.sideLinks}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="Business"><BusinessIcon color={color} /></Tooltip></ListItemIcon>
                        <ListItemText primary="Business" />
                    </ListItem>
                </Link>
            ) : ('')}

            {checkMenu('Customers') ? (
                <Link to='/dashboard/customers' className={classes.sideLinks}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="Customers"><PeopleAltIcon color={color} /></Tooltip></ListItemIcon>
                        <ListItemText primary="Customers" />
                    </ListItem>
                </Link>
            ) : ('')}

            {checkMenu('Tickets') ? (
                <Link to='/dashboard/tickets' className={classes.sideLinks}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="Tickets"><ForumIcon color={color} /></Tooltip></ListItemIcon>
                        <ListItemText primary="Tickets" />
                    </ListItem>
                </Link>
            ) : ('')}

            {trolley}

            {utility}

            {trash}

            <ListItem button onClick={() => setSettingToggle(!settingToggle)}>
                <ListItemIcon>
                    <Tooltip title="Settings"><SettingsIcon color={color} /></Tooltip>
                </ListItemIcon>
                <ListItemText primary="Settings" />
                {settingToggle ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={settingToggle} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {checkMenu('Ticket Settings') ? (
                        <Link to='/dashboard/ticketSettings' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="Ticket Settings"><PlaylistAddCheckIcon color={color} /></Tooltip></ListItemIcon>
                                <ListItemText primary="Ticket Settings" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkMenu('Support') ? (
                        <Link to='/dashboard/support' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="Support List"><SupervisedUserCircleIcon color={color} /></Tooltip></ListItemIcon>
                                <ListItemText primary="Support List" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Users') ? (
                        <Link to='/dashboard/settings/users' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Users"><SupervisorAccountIcon color={color} /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Roles') ? (
                        <Link to='/dashboard/settings/roles' className={classes.sideLinks}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Roles"><ListIcon color={color} /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Roles" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    <Link to='/dashboard/profile' className={classes.sideLinks}>
                        <ListItem button className={classes.nested}>
                            <ListItemIcon>
                                <Tooltip title="My Profile"><PersonIcon color={color} /></Tooltip>
                            </ListItemIcon>
                            <ListItemText primary="My Profile" />
                        </ListItem>
                    </Link>
                </List>
            </Collapse>
        </List>
    )
}

export default SideNav
