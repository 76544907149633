import React, { useState, useEffect } from 'react'
import {
    Button,
    Breadcrumbs,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../components/Table'
import axios from 'axios'
import config from '../../config'
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash'
import MuiAlert from '@material-ui/lab/Alert'
import { dateFormate } from '../../functions'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ListDisabledTickets = (props) => {
    const token = localStorage.getItem('tgToken')
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [open, setOpen] = useState(false)
    const [itemId, setItemId] = useState(null)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "ticket_id",
            label: "Ticket ID",
            options: {
                sort: true,
            }
        },
        {
            name: "created_at",
            label: "Ticket Received Date",
            options: {
                sort: true,
                customBodyRender: dateFormate
            }
        },
        {
            name: "glass_order_no",
            label: "Glass Order No",
            options: {
                sort: true,
            }
        },
        {
            name: "customer_po_no",
            label: "Customer PO No",
            options: {
                sort: true,
            }
        },
        {
            name: "glass_type",
            label: "Glass Type",
            options: {
                sort: true,
            }
        },
        {
            name: "warranty_reason",
            label: "Warranty Reason",
            options: {
                sort: false,
            }
        },
        {
            name: "glass_size",
            label: "Glass Size",
            options: {
                sort: true,
            }
        },
        {
            name: "received_date",
            label: "Glass Received Date",
            options: {
                sort: true,
                customBodyRender: dateFormate
            }
        },
        {
            name: "expected_date",
            label: "Expected Replacement Date",
            options: {
                sort: true,
                customBodyRender: dateFormate
            }
        },
        {
            name: "business_name",
            label: "Business",
            options: {
                sort: false,
            }
        },
        {
            name: "name",
            label: "Customer",
            options: {
                sort: false,
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let status
                    if (value === 'Created') {
                        status = <Chip label={value} style={{ backgroundColor: "#FFF6E2", color: "orange" }} />
                    } else if (value === 'Notified') {
                        status = <Chip label={value} style={{ backgroundColor: "#dae9f2", color: "#0c69a9" }} />
                    } else if (value === 'Delivered') {
                        status = <Chip label={value} style={{ backgroundColor: "#E2FAED", color: "#39D980" }} />
                    } else {
                        status = value
                    }
                    return status
                }
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        },
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search,
            disabled: 1
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/tickets'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                const action = (
                    <Tooltip title="Restore Ticket">
                        <IconButton onClick={() => handleClickOpen(item.id)}>
                            <RestoreFromTrashIcon />
                        </IconButton>
                    </Tooltip>
                )
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
            } else {
                config.debug && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const restoreItem = (itemId) => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/tickets/' + itemId + '/restore'
        axios.put(url, {}, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Restored.')
            setShowAlert(true)
            setOpen(false)
            getList()
        }).catch(error => {
            let message
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                else {
                    message = 'Try after some time.'
                }
                config.debug && console.log(error.response)
            } else if (error.request) {
                config.debug && console.log(error.request)
                message = 'Try after some time.'
            } else {
                config.debug && console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleClickOpen = (itemId) => {
        setOpen(true)
        setItemId(itemId)
    }

    const handleClose = () => {
        setOpen(false)
        setItemId(null)
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <MLink color="inherit" href="/#/dashboard/tickets">
                            Tickets
                        </MLink>
                        <Typography color="textPrimary">Disabled Tickets</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Restore Ticket</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to restore the Ticket?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => restoreItem(itemId)} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {progress}
            <Table
                title={'List Disabled Tickets'}
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
        </div>
    )
}

export default ListDisabledTickets
